import { Component, OnInit } from '@angular/core';
import { ChartComponent } from '../../../shared/components/chart/chart.component'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { StorageService } from '../../../shared/services/storage.service';
import { Reviewer } from '../../../store/reviewer/reviewer.interface';
import { DividerModule } from 'primeng/divider';
import { Store } from '@ngrx/store';
import { ToastService } from '../../../shared/services/toast.service';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog.service';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { Subscription, lastValueFrom } from 'rxjs';
import { StopwatchService } from '../../../shared/services/stopwatch.service';
import { TimeKeepingService } from '../../../shared/services/time-keeping.service';
import { ProjectService } from '../../../shared/services/project.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrackingTimeModalComponent } from './tracking-time-modal/tracking-time-modal.component';
import { ConflictQuestionsModalComponent } from './conflict-questions-modal/conflict-questions-modal.component';
import moment, { Moment } from 'moment';
import { Response, Role, Roles, ToDoList, getTsheets } from '../reviewer-dashboard.interface';
import { RouterModule, Router } from '@angular/router';
import { EmittersService } from '../../../shared/services/emitters.service';
import { ViewDocusignDocumentsComponent } from './view-docusign-documents-modal/view-docusign-documents-modal.component';
import { DocumentService } from '../../../shared/services/document-service';
import { UserService } from '../../../shared/services/user.service';
import { UserClientVm } from '../../../store/user/user.interface';
import { ViewDocumentsComponent } from './view-documents-modal/view-documents-modal.component';
import { ProfileCompletionModalComponent } from './profile-completion-modal/profile-completion-modal.component';
import { constants } from '../../../shared/constants/constants';
import { RateProjectComponent } from './rate-project-modal/rate-project-modal.component';
import { QuitPositionModalComponent } from './quit-position-modal/quit-position-modal.component';
import { OfferService } from '../../../shared/services/offers.service';
import { QuitConfirmationModalComponent } from './quit-confirmation-modal/quit-confirmation-modal.component';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { NotificationService } from '../../../shared/services/notifications.service';

@Component({
  selector: 'app-reviewer-dashboard',
  standalone: true,
  imports: [ChartComponent, ChipModule, FormsModule, ButtonModule, DropdownModule,
    CommonModule, CardModule, ProgressBarModule,
    PanelModule, TabViewModule, DataViewModule, DividerModule, DialogModule, FloatLabelModule,
    RouterModule, BadgeModule, TooltipModule],
  providers: [DialogService],
  templateUrl: './reviewer-dashboard.component.html',
  styleUrl: './reviewer-dashboard.component.scss'
})
export class DashboardReviewerComponent implements OnInit {
  user: UserClientVm | null = null;
  intervalId: any;
  subscription!: Subscription;
  profileStrengthValue!: number;
  showAvailableButton:boolean = false;
  reviewer!: Reviewer;
  showMultiTenantFeature = true;
  positions: any = [];
  endedPositions: any = [];
  selectedFuturePosition: any;
  selectedCurrentPosition: any;
  offers: any = [];
  visibleAcceptConfirmationModal = false;
  visibleRejectConfirmationModal = false;
  offerRejectReasons = this.reviewerService.getReasons();
  selectedReason: { name: string, label: string } | null = null;
  ref: DynamicDialogRef | undefined;
  conflictQuestionRef: DynamicDialogRef | undefined;
  roles: any = [];
  dailySecs = 0;
  isClockedIn: boolean = false;
  clockedInTimeSheet: any;
  dailyDuration: any;
  selectedRoleForTimmer!: string | null;
  contractorTimekeepingEnabled!: boolean;
  invoiceSubmittedOn!: string;
  tsheets: getTsheets[] = [];
  graphLabels: string[] = [];
  graphData =  [
    {
      label: 'Graph',
      data: [],
      backgroundColor: ['rgba(255, 159, 64, 0.2)'],
      borderColor: ['rgb(255, 159, 64)'],
      borderWidth: 1,
    }
  ];
  totalDuration!: { hours: string, minutes: string, seconds: string };
  loading!: boolean ;
  weekSecs: number = 0;
  weekDuration!: { hours: string, minutes: string, seconds: string};
  totalSecs: number = 0;
  chartWidth = 400;
  chartHeight = 200;
  enableTimeTrackerGraph = true ;
  toDoList: ToDoList[] = [];
  notifications: any = [];
  unavailableNotifications: any = [];
  isDocuSignDocumentsFetched = false;
  isDocuSignAdditionalEnvelopeDocumentsFetched = false;
  isDocumentRemaining = false;
  currentPositionEnvelop: any = null;
  currentPositionAdditionalEnvelopes: any = null;
  isUserProfileCompleted: boolean = false;
  onboarding!:  string;
  activeTabIndex = 0;
  currentOffer!: any;
  tenantSubscriber: any;
  todoListSubscriber: any;
  constructor(
    private reviewerService: ReviewerService, 
    private store: Store, 
    private storageService: StorageService,
    private toastService: ToastService, 
    private confirmationDialogService: ConfirmationDialogService, 
    private dialogService: DialogService,
    private stopwatchService: StopwatchService, 
    private timeKeepingService: TimeKeepingService, 
    private projectService: ProjectService,
    private offerService: OfferService,
    private router: Router, 
    private emitterService: EmittersService, 
    private documentService: DocumentService,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
      this.tenantSubscriber = this.emitterService.tenantEmitter.subscribe(()=>{
        this.initialization();
      });

      this.todoListSubscriber = this.emitterService.todoListEmitter.subscribe(async () => {
        await this.getToDoList();
        this.getNotifications();
      });
      this.onboarding = history.state.data?.from;
  }

  ngOnInit() {
    this.initialization();
  }

  ngOnDestroy(): void {
    this.tenantSubscriber.unsubscribe();
    this.todoListSubscriber.unsubscribe();
  }

  getStopwatchSubscription() {
    this.subscription = this.stopwatchService.getStopwatchState().subscribe(state => {
      if (state === 'running') {
        this.updateTime();
      } else {
        this.stopTimer();
      }
    });
  }

  startStopwatch(): void {
    if(this.stopwatchService.intervalId){
      return
    }   
    this.stopwatchService.startTime();
  }

  startTimer(): void {
    this.stopwatchService.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  stopTimer(): void {
    if(this.stopwatchService.intervalId === null){
      return
    }
    this.stopwatchService.stopTime();
  }

  getReviewer(){
    this.reviewerService.fetchReviewer().subscribe((reviewer) => {
      this.reviewer = reviewer;
    });
  }

  async initialization(offerAccepted: boolean = false, isFuturePosition: boolean = false): Promise<void> {
    this.reviewerService.fetchReviewerAndUpdate();
    this.user = this.userService.getSessionUser();
    this.getReviewer();
    this.selectedCurrentPosition = null;
    this.selectedFuturePosition = null;
    await this.getPositions();
    await this.getOffers();
    await this.getNotifications();
    if(!this.positions.length && !this.offers.length){
      this.showAvailableButton = true;
    }
    this.getStopwatchSubscription();
    await this.getTsheetList(this.getCurrentWeek());
    await this.getUserProfileStrength();
    await this.getToDoList();
    this.setDefaultTabForRedirection(offerAccepted, isFuturePosition);
  }

  async onTabChange(e: any,index: any): Promise<void>{
    switch (this.activeTabIndex) {
      case 1:
        if(!this.getCurrentPositions().length && this.getFuturePositions().length){
          this.loadFuturePosition();
        }
        break;
      case 2:
        if(this.getCurrentPositions().length && this.getFuturePositions().length){
          this.loadFuturePosition();
        }
        this.getToDoList();
        break;
      default:
        break;
    }
  }

  async getNotifications(){
    const resp = await this.notificationService.getNotifications();
    this.notifications = resp;
    if(this.notifications.length){
      this.showNotificationsInModal();
    }
  }

  async markRead(item: any){
    await this.notificationService.markAsRead(item._id)
    item.isRead = true;
  }

  showNotificationsInModal(index = 0){
    if(index >= this.notifications.length){
      this.initialization();
      return;
    }
    this.confirmationDialogService.confirm({
      message: this.notifications[index].message,
      header: this.notifications[index].subject || 'Notification',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Ok",
      rejectVisible: false,
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.markRead(this.notifications[index]);
        this.confirmationDialogService.close();
        setTimeout(() => {
          this.showNotificationsInModal(index+1);
        }, 500)
      },
    });
  }

  setDefaultTabForRedirection(offerAccepted: boolean = false, isFuturePosition: boolean = false): void {
    if(offerAccepted) {
      this.activeTabIndex = isFuturePosition ? 2 : 1;
      return;
    }
    if(this.toDoList.length){
      this.activeTabIndex = 0;
      return;
    }
    if(this.selectedCurrentPosition){
      this.activeTabIndex = 1;
      return;
    }
    this.activeTabIndex = 0;
  }

  quitPosition(position: any) {
    const quitConfirmationModal = this.dialogService.open(QuitConfirmationModalComponent,{
      header: 'End Role',
      width: '50vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    quitConfirmationModal.onClose.subscribe(async (data) => {
      if(data.isConfirm){
        this.reviewerService.quitPosition(position._id, data.reason).subscribe(() => {
          this.toastService.showSuccess("Position Quit Successfully.");
          const quitPositionModal = this.dialogService.open(QuitPositionModalComponent, {
            data: {
              position,
              project: position.project,
              role: position.role
            },
            header: 'Role Ended',
            closable: false,
            width: '50vw',
            contentStyle: { overflow: 'hidden' },
            breakpoints: {
              '960px': '75vw',
              '640px': '90vw'
            }
          });
      
          quitPositionModal.onClose.subscribe(async (data: any) => {
            if (data) {
              this.openRatingModal({
                position,
                project: position.project,
                role: position.role,
                firm: position.firm
              }, true);
            } else {
              this.onViewTimeSheet(position, true);
            }
          });
        });
      }
    })
  }

  openRatingModal(ratingInfo: any, shouldCallAcknowledgeEndedPosition: boolean = false) {
    const ratingModal = this.dialogService.open(RateProjectComponent, {
      data: ratingInfo,
      header: 'Rate Project',
      width: '50vw',
      closable: false,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    ratingModal.onClose.subscribe(async (ratingResponse: any) => {
      if(ratingResponse?.isConfirm){
        const ratingPayload = {
          project: ratingInfo.project._id,
          author: this.user?._id,
          stars: ratingResponse.rating
        }
        await this.projectService.createProjectRating(ratingInfo.firm._id, ratingPayload);
        this.updateTodoListItems('Role Ended', ratingInfo.project._id, ratingInfo.role._id);
        this.toastService.showSuccess('Rated successfully.');
        this.emitterService.isCurrentlyAssignedEmitter.emit();
        if(shouldCallAcknowledgeEndedPosition){
          await this.flowCompleted(ratingInfo.position);
        }
      }
      this.initialization();
    });
  }

  showRoleEndedModal(position: any): void{
    const quitPositionModal = this.dialogService.open(QuitPositionModalComponent, {
      data: {
        position,
        project: position.project,
        role: position.role
      },
      header: 'Role Ended',
      closable: false,
      width: '50vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    quitPositionModal.onClose.subscribe(async (data: any) => {
      if (data) {
        this.openRatingModal({
          position,
          project: position.project,
          role: position.role,
          firm: position.firm
        }, true);
      } else {
        this.onViewTimeSheet(position, true);
      }
    });
  }

  async flowCompleted(position: any): Promise<void>{
    await this.offerService.acknowledgeEndedPosition(position._id);
    let message = 'Project ended successfully';
    if(position.presumptive && position.presumptive.status === constants.presumptiveStatus.DENIED){
        message = 'Removed Succesfully';
    }
    this.toastService.showSuccess(message);
    this.initialization();
  }

  async getUserProfileStrength() {
    this.profileStrengthValue = 0;
    
    if (this.showMultiTenantFeature) {
      let currentFirm = this.storageService.getTenant();
      if (currentFirm) {
        this.profileStrengthValue = await this.reviewerService.setProfileStrengthForStaftr(this.reviewerService.getReviewer());
        this.checkProfileCompletion();
      }
    }
  }
  
  async getOffers() {
    this.offers = await lastValueFrom(this.reviewerService.getAllOffers());
    this.offers = this.offers.map((offer: any) => {
      const timezone = offer.role.timezone?.split('[')[0] || 'UTC'; 
      return {
        ...offer,
        role: {
          ...offer.role,
          startDate: moment.tz(offer.role.startAt, timezone).format('YYYY-MM-DDTHH:mm:ssZ'),
          startTime: moment.tz(offer.role.startAt, timezone).format('hh:mm a')
        },
        positionStarted: offer.positionStarted
          ? moment.tz(offer.positionStarted, timezone).format('YYYY-MM-DDTHH:mm:ssZ')
          : offer.positionStarted,
        positionStartedTime: offer.positionStarted
        ? moment.tz(offer.positionStarted, timezone).format('hh:mm a')
        : offer.positionStarted,
      };
    });
    
    this.viewOffer();
  }

  viewOffer() {
    const offerIds = this.offers.map((offer:any) => offer._id);
    this.reviewerService.viewAllOffers(offerIds);
  }
  
  async getPositions() {
    const res = await lastValueFrom(this.reviewerService.getPositions());
    if(res.ended && res.ended.length){
      res.ended.map((endedPosition: any) => {
        endedPosition.name = endedPosition.project.codeName + ' -- ' + endedPosition.role.roleType;
      })
      this.endedPositions = res.ended;
      this.showRoleEndedModal(this.endedPositions[0]);
    }
    this.positions = await Promise.all(res.occupied.map(async (position: any) => {
      const myCurrentDocument = await this.documentService.getMyCurrentDocuments(position.project._id);
      position.docs = myCurrentDocument || {};
      position.name = position.project.codeName + ' -- ' + position.role.roleType;
      const timezone = position.role.timezone?.split('[')[0] || 'UTC'; 

      position.role.startDate = moment.tz(position.role.startAt, timezone).format('YYYY-MM-DDTHH:mm:ssZ');
      position.role.startTime = moment.tz(position.role.startAt, timezone).format('hh:mm a');
      
      return position;
    }));
    this.getTotalProjectDuration();
  }

  getFuturePositions() {
    const futurePositions = this.positions.filter((p: any) => p.isFuture);
    if (futurePositions.length && !this.selectedFuturePosition) {
      this.selectedFuturePosition = futurePositions[0]
    }
    return futurePositions;
  }

  getCurrentPositions() {
    const currentPositions = this.positions.filter((p: any) => !p.isFuture);
    if (currentPositions.length && !this.selectedCurrentPosition) {
      this.selectedCurrentPosition = currentPositions[0]
    }
    return currentPositions;
  }

  loadCurrentPosition(){
    if(this.selectedCurrentPosition){
      this.getToDoList();
      if (!this.selectedCurrentPosition.docs && this.selectedCurrentPosition.project.documents.length > 0) {
        this.getDocs();
      } else {
          this.checkRequiredDocumentsOnFlag(this.selectedCurrentPosition);
      }  
    }
  }

  loadFuturePosition(){
    if(this.selectedFuturePosition){
      if (!this.selectedFuturePosition.docs && this.selectedFuturePosition.project.documents.length > 0) {
        this.getFutureDocs();
      } else {
          this.checkRequiredDocumentsOnFlag(this.selectedFuturePosition);
      }  
    }
  }

  async getDocs(){
    const documents = await this.documentService.getMyCurrentDocuments(this.selectedCurrentPosition.project._id);
    this.selectedCurrentPosition.docs = documents;
  }

  async getFutureDocs(){
    const documents = await this.documentService.getMyCurrentDocuments(this.selectedFuturePosition.project._id);
    this.selectedFuturePosition.docs = documents;
  }

  async checkRequiredDocumentsOnFlag(position?:any){
    let positionToCheck = position || this.selectedCurrentPosition;
    if(positionToCheck.firm.isDocusignEnabled && positionToCheck.docusignEnabled) {
      await this.checkRequiredDocuSignDocumentsUploaded(positionToCheck);
    } else{
      this.checkRequiredDocumentsUploaded(positionToCheck);
    }
    if(!this.isDocumentRemaining && !positionToCheck.isFuture){
      this.updateTodoListItems('Documents', positionToCheck.project._id.toString());
    }
  }

  markAvailable() {
    this.reviewerService.updateMarkedAvailability().subscribe((resp) => {
      this.getReviewer();
      this.toastService.showSuccess(`Your availability has been noted and conveyed to ${this.user?.firm.name}`);
    });
  }

  openAcceptConfirmationModal(offer: any): void {
    this.currentOffer = offer;
    this.visibleAcceptConfirmationModal = true;
  }

  openRejectionConfirmationModal(offer: any): void {
    this.currentOffer = offer;
    this.visibleRejectConfirmationModal = true;
  }

  async accept($event: any, confirm = false) {
    this.visibleAcceptConfirmationModal = false;
    if (!confirm) {
      return;
    }
    if (this.currentOffer.role && this.currentOffer.role.hasConflict && this.currentOffer.role.conflictQuestions.length > 0) {
      this.conflictQuestionsModal($event)
    } else {
      await this.offerAccept();
    }
  }

  async offerReject(confirm = false): Promise<void> {
    if (!confirm) {
      this.visibleRejectConfirmationModal = false;
      return;
    }
    if (!this.selectedReason) {
      this.toastService.showError('Please select the reason.');
      return;
    }
    this.visibleRejectConfirmationModal = false;
    await this.reviewerService.rejectOfferWithReason(this.currentOffer?._id, {reason: this.selectedReason.label});
    this.initialization();
  }

  async offerAccept() {
    try{
      await this.reviewerService.acceptOffer(this.currentOffer?._id)
      const isFuturePosition = this.currentOffer?.role.isFuture;
      this.emitterService.isCurrentlyAssignedEmitter.emit();
      this.initialization(true, isFuturePosition);
    }catch(err: any){
        this.showErrorModal(err?.error?.message || 'An unknown error occurred. Do you want to retry?');
    }
  }

  showErrorModal(message: string) {
    this.confirmationDialogService.confirm({
      message: message,
      header: '',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.offerService.queueOffer(this.currentOffer?._id, true);
      },
      reject: () => {
      },
    });
  }

  async conflictQuestionsModal($event: any) {
    const data = {
      conflictQuestions: this.currentOffer.role.conflictQuestions
    }
    $event.stopPropagation();
    this.conflictQuestionRef = this.dialogService.open(ConflictQuestionsModalComponent, {
      data: data,
      header: 'Screening Question',
      width: '30vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.conflictQuestionRef.onClose.subscribe(async (data: any) => {
      if (data) {
        if (data.isOfferAccepted) {
          await this.offerAccept();
        } else {
          let rejectedDetails: any = {
            'reason': 'Conflicted Out'
          };
          if (data.conflictReasons) {
            rejectedDetails['conflictReasons'] = data.conflictReasons;
          }

          await this.reviewerService.rejectOfferWithReason(this.currentOffer?._id, rejectedDetails);
          this.toastService.showError(`Your answer to the question “${JSON.parse(data.conflictReasons.question).title}” has screened you out of this position.`);
          this.initialization();
        }
      }
    });
  }

  getTotalProjectDuration() {
    const currentPosition = this.getCurrentPositions();
    if (currentPosition.length > 0) {
      const position = currentPosition[0];
      const navigatedWeek = this.getCurrentWeek();
      let payload: any = { role_id: position.role.id, project_id: position.project._id };
      if (navigatedWeek && navigatedWeek.startDate) {
        payload.dateRange = navigatedWeek;
        payload.inclusive = true;
        payload.position = position._id.toString();
        payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
        this.timeKeepingService.getTsheets(payload).subscribe((res: any) => {
          if (res && res.stats && res.stats.totalHoursOnProject) {
            this.calcDailyDuration(res.daily);
            this.getRoles(position.project._id);
          }
        })
      }
    }
  }

  getCurrentWeek() {
    let startOfWeek = moment().isoWeekday(1);
    startOfWeek = startOfWeek.startOf('day');
    let endOfWeek = moment(startOfWeek).add(6, 'days');
    endOfWeek = endOfWeek.endOf('day');
    const week = { startDate: startOfWeek, endDate: endOfWeek };
    return week;
  }

  getRoles(projectId: string) {
    this.projectService.getRolesByReviewerProject([projectId]).subscribe((resp: Roles) => {
      this.roles = [];
      this.roles = resp
      .filter((role: any) => 
        role.status !== constants.roleStatus.archive && !role.isDeleted
      )
      .map((role: Role) => {
        return {
          name: `${role.projectName}-${role.roleType}`,
          value: role._id
        }
      });
    })
  }

  calcDailyDuration(tsheets: getTsheets[]){
    this.dailySecs = 0;
    this.isClockedIn = false;
    this.clockedInTimeSheet = null;
    this.selectedRoleForTimmer= null;
    this.stopTimer();
    tsheets?.forEach((tsheet: any) => {
      if (tsheet.onTheClock) {
        this.isClockedIn = true;
        this.clockedInTimeSheet = tsheet.id;
        this.selectedRoleForTimmer= tsheet.role._id;
        this.enableTick();
      }
      this.dailySecs += tsheet.duration;
    });
    this.dailyDuration = this.timeKeepingService.secondsToHMS(this.dailySecs);
  }

  enableTick() {
    this.startStopwatch();
    this.updateTime();
  }

  updateTime() {
    this.dailySecs++;
    this.dailyDuration = this.timeKeepingService.secondsToHMS(this.dailySecs);
  }

  disableTick() {
    this.stopTimer();
  }

  clockIn(roleId: any) {
    this.selectedRoleForTimmer = roleId;
    this.timeKeepingService.addTSheet({ role_id: roleId }).subscribe((res: any) => {
      this.isClockedIn = true;
      this.clockedInTimeSheet = res.id;
      this.enableTick();
    })
  }

  clockOut() {
    this.timeKeepingService.updateTSheet({ id: this.clockedInTimeSheet, clock_out: true, role_id: this.selectedRoleForTimmer, timezone: moment().format('Z') })
      .subscribe((res: any) => {
        this.disableTick();
        this.isClockedIn = false;
        this.clockedInTimeSheet = null;
        //this.toast.showSuccess('You hours have been tracked successfully.');
      })
  }

  showRolesModal($event: any) {
    const data = {
      roles: this.roles
    }
    $event.stopPropagation();
    this.ref = this.dialogService.open(TrackingTimeModalComponent, {
      data: data,
      header: 'Role Selection',
      width: '30vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.ref.onClose.subscribe((data: any) => {

      if (data) {
        this.clockIn(data.value)
      }

    });
  }

  openViewDocumentsModal(position?:any): void{
    let positionToCheck = position || this.selectedCurrentPosition;
    if(positionToCheck.firm.isDocusignEnabled && positionToCheck.docusignEnabled ){
      this.openDocuSignDocModal(positionToCheck);
    } else {
      this.showDocumentsModal(positionToCheck); 
    }
  }

 async openDocuSignDocModal(position?:any) {
    if(!position.isDocuSignDocumentsFetched){
     await this.checkRequiredDocumentsOnFlag(position);
    }
    if(!position.envelop){
      this.toastService.showInfo("No Docusign documents found");
      return;
    }
    this.ref = this.dialogService.open(ViewDocusignDocumentsComponent, {
      data: {
        documents: position.envelop.documents, 
        envelopeId: position.envelop.envelopeId,
        status: position.envelop.status,
        additionalEnvelopes: position.additionalEnvelopes,
        isReviewerModal: true
      },
      header: 'Documents',
      width: '70vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    this.ref.onClose.subscribe((message: any) => {
      this.checkRequiredDocumentsOnFlag(position);
    });
  }

  showDocumentsModal(position?:any) {
    this.ref = this.dialogService.open(ViewDocumentsComponent, {
      data: {
        position: position
      },
      header: 'Documents',
      width: '70vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    this.ref.onClose.subscribe((message: any) => {
      this.checkRequiredDocumentsOnFlag(position);
    });
  }

  async checkRequiredDocuSignDocumentsUploaded(position: any){
    if(!position.isDocuSignDocumentsFetched){
      const roleId = position.role.id;
      const projectId = position.project.id;
      const positionId = position._id;
      const reviewerId = position.reviewer;
      const additionalEnvelopes = true;

      try {
        const response = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId, false, false, false);
        if(response){
          position.isDocuSignDocumentsFetched = true;
          position.isDocumentRemaining = response.status !== 'completed';
          position.envelop = response; 
        }
        const responseOfAllEnvelopes = await this.documentService.getEnvelopeDocumentsListing(projectId, roleId, reviewerId, positionId, false, additionalEnvelopes, false);
        if(responseOfAllEnvelopes){
          position.isDocuSignAdditionalEnvelopeDocumentsFetched = true;
          position.isDocumentRemaining = !!(responseOfAllEnvelopes.find((ev: any) => ev.status !== 'completed' ));
          position.additionalEnvelopes = responseOfAllEnvelopes; 
        }
      } catch (error: any) {
        position.isDocuSignDocumentsFetched = true;
        position.isDocuSignAdditionalEnvelopeDocumentsFetched = true;
        this.toastService.showError((error && error.message ) || 'Error occured');
      }
    
    }
  }

  checkRequiredDocumentsUploaded(pos: any) {
    pos.isDocuSignDocumentsFetched = false;
    if (pos.docs && pos.docs.blank) {
      pos.isDocuSignDocumentsFetched = true;
      const obj = pos.docs.blank;
      Object.keys(obj).forEach((key) => {
        if (!pos.docs.filled[this.user?._id || ''] || !pos.docs.filled[this.user?._id || ''][key]) {
          pos.isDocumentRemaining = true;
          if (!this.isDocumentRemaining) {
            this.isDocumentRemaining = true;
          }
        } else {
          this.isDocumentRemaining = false;
        }
      });
    }
  }

  goToTimekeeping(){
    this.router.navigateByUrl('/reviewer/timekeeping');
  }
  
  private removeDuplicatesById(tsheets: any[]): any[] {
    const uniqueTsheets = tsheets.reduce((acc, tsheet) => {
      acc[tsheet._id] = tsheet;
      return acc;
    }, {});

    return Object.values(uniqueTsheets);
  }

  getWeeklytsheets(res: Response): getTsheets[] {
    const lastInvoice = res?.lastInvoice;
    let tsheets = res?.list;

    if (lastInvoice && lastInvoice.timesheets && lastInvoice.timesheets.length) {
      tsheets = [...res?.list, ...lastInvoice.timesheets];
      tsheets = this.removeDuplicatesById(tsheets);
    }

    tsheets = tsheets.filter((ts:any) => !ts.invoice || (lastInvoice && ts.invoice === lastInvoice._id));

    return tsheets;
  }

  calcTotalDuration(tsheets: getTsheets[]){
    this.totalSecs = 0;
    tsheets = tsheets || [];

    tsheets.forEach((tsheet) => {
      this.totalSecs += tsheet.duration;
    });
    return tsheets;
  }

  calcWeekDuration(tsheets: getTsheets[]) {
    this.weekSecs = 0;
    tsheets?.forEach((tsheet) => {
      if (tsheet.onTheClock) {
        this.clockedInTimeSheet = tsheet.id;
      }
      this.weekSecs += tsheet.duration;
    });

    this.weekDuration = this.timeKeepingService.secondsToHMS(this.weekSecs);
  }
  
  getPreviousWeek(navigatedWeek: { startDate: moment.Moment, endDate: moment.Moment }): { startDate: moment.Moment, endDate: moment.Moment } {
    return {
      startDate: navigatedWeek.startDate.clone().subtract(7, 'days'),
      endDate: navigatedWeek.endDate.clone().subtract(7, 'days')
    };
  }

  async getTsheetList(navigatedWeek: {startDate: Moment, endDate: Moment}, change?: any,  msg?: any): Promise<void> {
    const position: any = this.getCurrentPositions();

    if (position.length == 0) { return; }

    this.selectedCurrentPosition = change || position[0];
    const currentPosition = this.selectedCurrentPosition;

    let payload: any = { role_id: currentPosition.role._id };
    this.contractorTimekeepingEnabled = currentPosition.project.contractorTimekeepingEnabled;
    this.isDocuSignDocumentsFetched = false;

    if (navigatedWeek && navigatedWeek.startDate) {
      payload.dateRange = navigatedWeek;
      payload.inclusive = true;
      payload.position = currentPosition ? currentPosition._id.toString() : "";
      payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
    }

    const promises = [
      await lastValueFrom(this.timeKeepingService.getTsheets(payload))
    ];

    payload.weekId = payload.weekId - 1;
    payload.dateRange = this.getPreviousWeek(navigatedWeek);
    promises.push(await lastValueFrom(this.timeKeepingService.getTsheets(payload)));
    const responses = promises;
    
    const res = responses[0];
    if (this.contractorTimekeepingEnabled) {
      this.invoiceSubmittedOn = res.invoiceSubmittedOn ? moment(res.invoiceSubmittedOn).format("MM/DD/YYYY hh:mm:ss a") : "";
      this.tsheets = this.getWeeklytsheets(res);
      this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
      this.calcWeekDuration(this.tsheets);
      this.calcTotalDuration(this.tsheets);
    } else {
      this.tsheets = this.getWeeklytsheets(res);
      this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
      this.calcWeekDuration(this.tsheets);
      this.tsheets = this.calcTotalDuration(this.tsheets);
    }

    this.calcDailyDuration(res.daily);

    const combinedWeeks = { ...responses[1].stats.weeklyDistributedHours, ...res.stats.weeklyDistributedHours };
    this.graphLabels = Object.keys(combinedWeeks).map(function (k) {
      let key = k;
      let x = key.split('/');
      return x[0] + '/' + x[1];
    });

    this.graphData[0].data = [];
    const graphDataPoints: any = [];
    Object.keys(combinedWeeks).forEach(key => {
      graphDataPoints.push(combinedWeeks[key].dailyTotalHours);
    });
    this.graphData[0].data = graphDataPoints;
    this.totalDuration = res.stats.totalHoursOnProject;
    this.loading = false;
    this.loadCurrentPosition();
  }

  async onChangeFuturePosition(navigatedWeek: {startDate: Moment, endDate: Moment}, change?: any,  msg?: any): Promise<void> {
    
    // commented because of we have to continue this function in next sprint
    
    // const position: any = this.getFuturePositions();

    // if (position.length == 0) { return; }

    // this.selectedFuturePosition = change ? change: position[0];
    // const futurePosition = this.selectedFuturePosition;

    // let payload: any = { role_id: futurePosition.role._id };
    // this.contractorTimekeepingEnabled = futurePosition.project.contractorTimekeepingEnabled;
    // this.isDocuSignDocumentsFetched = false;

    // if (navigatedWeek && navigatedWeek.startDate) {
    //   payload.dateRange = navigatedWeek;
    //   payload.inclusive = true;
    //   payload.position = futurePosition ? futurePosition._id.toString() : "";
    //   payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
    // }

    // const promises = [
    //   await lastValueFrom(this.timeKeepingService.getTsheets(payload))
    // ];

    // payload.weekId = payload.weekId - 1;
    // payload.dateRange = this.getPreviousWeek(navigatedWeek);
    // promises.push(await lastValueFrom(this.timeKeepingService.getTsheets(payload)));
    // const responses = promises;
    
    // const res = responses[0];
    // if (this.contractorTimekeepingEnabled) {
    //   this.tsheets = this.getWeeklytsheets(res);
    //   this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
      
    //   this.calcWeekDuration(this.tsheets);
    //   this.calcTotalDuration(this.tsheets);
    // } else {
    //   this.tsheets = this.getWeeklytsheets(res);
    //   this.tsheets = this.tsheets.concat(this.getWeeklytsheets(responses[1]));
    //   this.calcWeekDuration(this.tsheets);
    //   this.calcTotalDuration(this.tsheets);
    // }

    // this.calcDailyDuration(res.daily);

    // const combinedWeeks = { ...responses[1].stats.weeklyDistributedHours, ...res.stats.weeklyDistributedHours };
    // this.graphLabels = Object.keys(combinedWeeks).map(function (k) {
    //   let key = k;
    //   let x = key.split('/');
    //   return x[0] + '/' + x[1];
    // });

    // this.graphData[0].data = [];
    // const graphDataPoints: any = [];
    // Object.keys(combinedWeeks).forEach(key => {
    //   graphDataPoints.push(combinedWeeks[key].dailyTotalHours);
    // });
    // this.graphData[0].data = graphDataPoints;
    // this.totalDuration = res.stats.totalHoursOnProject;
    // this.loading = false;
     this.loadFuturePosition();
  }

  async getToDoList(): Promise<void>{

    this.toDoList = [];

    let currentFirm = this.storageService.getTenant().firm._id;

    this.toDoList = await lastValueFrom(this.reviewerService.getToDoList(this.selectedCurrentPosition?.role?._id || '', this.selectedCurrentPosition?.project?._id || '', currentFirm));

    this.toDoList.forEach((list: ToDoList) => {

      if(list.actions.length){
        list.actions.forEach((action) => {
          if(action.label === 'View Documents'){
            action.link = () => {
              this.openViewDocumentsModal();
            }
          }
          else if (action.label === 'View Message') {
            action.link = () => {
              this.router.navigateByUrl('reviewer/messages',
                {
                  state: {
                    data: {roleId: list.roleId}
                  }
                }
              );
            }
          }
          else if (action.label === 'Verify Time Records') {
            let position = this.endedPositions.find((p: any) => p.role.id == list.roleId);
            if (position?.isFuture) {
              list.actions.splice(list.actions.indexOf(action), 1);
            } else {
              action.link = () => {
                if(position){
                  this.onViewTimeSheet(position, true);
                }
              }
            }
          }
          else if(action.label === 'Rate Project') {
            action.link = () => {
              this.rateProjectToDoMethod(list);
            }
          }
          else if(action.label === 'Edit Profile') {
            action.link = () => {
              this.router.navigateByUrl('reviewer/profile/main');
              this.updateTodoListItems('More Information Required');
            }
          }
        })
      }
    });

    if(!this.isUserProfileCompleted){
      this.toDoList.push(
        {
          _id: '0',
          header: 'Welcome to Staftr',
          body: 'Welcome to Staftr. To best position yourself to receive job offers please make sure to complete your Profile.\n It’s also recommended you download the Staftr mobile app and set your preferred notification settings',
          actions: [
            {
              label: 'Edit Profile',
              link: () => {
                this.router.navigateByUrl('reviewer/profile/main');
              }
            }, 
            {
              label: 'ACCOUNT SETTINGS',
              link: () => {
                this.router.navigateByUrl('reviewer/settings');
              }
            }
          ]
        }
      );
    }
  }

  rateProjectToDoMethod(list: any){
    let position = this.endedPositions.find((p: any) => p.role.id == list.roleId);
    if(position){
      this.openRatingModal({
        position,
        project: position.project,
        role: position.role,
        firm: position.firm
      }, true);
    }
  }

  onViewTimeSheet(selectedCurrentPosition: any, displayApproveTime = false){
    const data = {
      name: selectedCurrentPosition.name,
      value: selectedCurrentPosition.role.id,
      displayApproveTime
    }
    this.router.navigateByUrl('reviewer/timekeeping',
      {
        state: {
          data: data
        },
      }
    );
  }

  checkProfileCompletion(){
    this.isUserProfileCompleted = this.profileStrengthValue === 100;
    if(!this.isUserProfileCompleted && this.onboarding === constants.onboarding){
      this.ref = this.dialogService.open(ProfileCompletionModalComponent, {
        header: 'Welcome to Staftr',
        width: '30vw',
        contentStyle: { overflow: 'hidden' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
      });
    }
  }

  updateTodoListItems(type: string, project?: string, role?: string){
    let obj = this.getTodoListItem(type);
    if(obj){
      let query = {_id: obj._id, role: role, project: project};
      this.reviewerService.updateNotificationHistory(query, 'complete').subscribe(() => {
        this.getToDoList();
      });
    }
  }

  getTodoListItem(type: string){
    return this.toDoList.find((e) => e.header.includes(type));
  }

  documentsExist(position?:any): boolean{
    let positionToCheck = position? position : this.selectedCurrentPosition;
    return Object.keys(positionToCheck.docs.blank).length? true : false
  }
}
